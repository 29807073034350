import Rails from '@rails/ujs';
import sweetAlert from 'sweetalert';
import SidePanel from './sidepanel';

const EditEventPartDetails = {
  start() {
    SidePanel.onUpdate(($sidepanel) => {
      $sidepanel
        .find('.js-activity-attendance')
        .on('change', 'input[type=radio]', this.handleAttendanceChange);
      $sidepanel
        .find('.js-confirm-waitlist-changes')
        .click(this.handleSubmit);
      $sidepanel
        .find('.js-activity-type')
        .on('change', 'input[type=radio]', this.handleActivityTypeChange);
      $sidepanel
        .find('.js-activity-type input[type=radio]:checked')
        .trigger('change');
    });
  },

  handleActivityTypeChange(e) {
    const $askIfAttending = $(e.currentTarget).closest('form').find('.js-ask-if-attending');
    const $linkAttendance = $(e.currentTarget).closest('form').find('.js-link-attendance');

    if (e.currentTarget.value === 'lead') {
      // Backend sets Link attendance
      $askIfAttending.attr('disabled', true);
      $linkAttendance.prop('checked', true).change();
    } else if (!$linkAttendance.attr('disabled')) {
      $askIfAttending.attr('disabled', false);
    }
  },

  handleAttendanceChange(e) {
    const $form = $(e.currentTarget).closest('form');
    const $attendanceLimitOptions = $form.find('.js-attendance-limit-options');
    const $responsesAccept = $form.find('.js-event-part-responses-accept');
    if (e.currentTarget.value === 'true') {
      $attendanceLimitOptions.removeClass('is-hidden');
      $responsesAccept.removeClass('is-hidden');
    } else {
      $attendanceLimitOptions.addClass('is-hidden');
      $responsesAccept.addClass('is-hidden');
    }
  },

  handleSubmit(e) {
    e.stopImmediatePropagation();
    const messages = [];
    const $content = $(e.currentTarget).closest('.sidepanel--replaceable');
    const $form = $content.find('form');
    const selectedAttendanceLimitSource = $content
      .find('.js-attendance-limit-options input[type="radio"]:checked')
      .val();
    let newSpotsLimit;
    if (selectedAttendanceLimitSource === 'custom') {
      newSpotsLimit = parseInt($content.find('.js-attendance-options--custom input').val(), 10) || 0;
    } else if (selectedAttendanceLimitSource === 'place') {
      newSpotsLimit = $content.find('.js-spots-placeholder').data('spotsCount');
    }
    const attendingPeopleCount = $content
      .find('.js-attendance-limit-options')
      .data('attendingCount');
    const waitlistedCount = $content
      .find('.js-add-waitlist')
      .data('waitlistedCount');
    const hasWaitlist = $content.find('.js-add-waitlist input[type="radio"]:checked')
      .val() === 'true';
    const hasChangedWaitlistToNo = $content.find('.js-add-waitlist').data('initialValue') && !hasWaitlist;
    if (hasChangedWaitlistToNo && waitlistedCount > 0) {
      // Case 2
      messages.push(`Pressing Confirm will remove the Waitlist and ${waitlistedCount} people will be set to Not Attending.`);
    }
    if (selectedAttendanceLimitSource === 'none' && waitlistedCount > 0) {
      // Case 5
      messages.push(`Pressing Confirm will remove the Waitlist and ${waitlistedCount} people will be set to Attending.`);
    }
    if (newSpotsLimit < attendingPeopleCount) {
      const moveToWaitlistCount = attendingPeopleCount - newSpotsLimit;
      if (hasWaitlist) {
        // case 1A
        messages.push(`The new Attending Limit is lower than the number of attendees. Pressing Confirm will move ${moveToWaitlistCount} people to the Waitlist based on response time.`);
      } else {
        // case 1B
        messages.push('The new Attending Limit is lower than the number of attendees. Pressing Confirm will keep the earliest respondents as Attending and set the rest to Not Attending.');
      }
    }

    if (messages.length > 0) {
      sweetAlert({
        title: 'Are you sure?',
        content: {
          element: 'span',
          attributes: {
            innerHTML: messages.join('<br>'),
          }
        },
        icon: 'warning',
        buttons: ['Cancel', 'Confirm'],
        dangerMode: false
      })
        .then((willSubmit) => {
          if (willSubmit) {
            Rails.fire($form.get(0), 'submit');
          }
        });
    } else {
      Rails.fire($form.get(0), 'submit');
    }
  }
};

export default EditEventPartDetails;
